<template>
  <div class="settings">
    <h1>settings</h1>
    <p>enter your kinopio API key (do not share)</p>
    <input v-model="apiKey" />
    <p><button @click="setApiKey">Set API key</button></p>
    <p>version 0.0.1</p>
    <p>Made by <a href="https://adamprocter.co.uk">Adam Procter</a></p>
    <p>
      Free Open Source Software - see
      <a href="https://git.soton.ac.uk/adam-procter/kinopio-capture">Code</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Settings',

  data() {
    return {
      apiKey: '',
    }
  },

  methods: {
    setApiKey() {
      this.$store.commit('SET_APIKEY', this.apiKey)
    },
  },
}
</script>
